<template>

  <b-modal v-model="isVisible"
           :hideFooter="true"
           size="lg">
    <template v-slot:title>
      <b-overlay :show="modal.isLoading" rounded="sm" variant="transparent">
      {{ modal.title }}
      </b-overlay>
    </template>

    <b-row v-if="!modal.isLoading">
      <b-col>
        <draggable v-model="list.enabled"
                   :group="`datagrid-${selectedDatagrid}`"
                   @change="onChangeEnabledList"
                   class="list-group h-100"
                   itemKey="key">
          <template #header>
            <h5>Enabled</h5>
          </template>
          <template #item="{ element }">
            <b-list-group-item class="list-group-item cursor-move">{{ element.label }}</b-list-group-item>
          </template>
        </draggable>
      </b-col>
      <b-col>
        <draggable v-model="list.disabled"
                   :group="`datagrid-${selectedDatagrid}`"
                   class="list-group h-100"
                   itemKey="key">
          <template #header>
            <h5>Disabled</h5>
          </template>
          <template #item="{ element }">
            <b-list-group-item class="list-group-item cursor-move">{{ element.label }}</b-list-group-item>
          </template>
        </draggable>
      </b-col>
    </b-row>
    <div v-if="!modal.isLoading" class="d-flex align-items-center justify-content-end flex-stack mt-3 gap-3">
      <b-button variant="secondary"
                title="Reset to default"
                :disabled="metricStatsPresetsListIsUpdating"
                @click="resetList">
        Reset
      </b-button>
      <b-button variant="secondary"
                title="Revert recent changes"
                :disabled="updatedMetricStatsPreset === null || metricStatsPresetsListIsUpdating"
                @click="revertList">
        Revert
      </b-button>
      <b-button variant="primary"
                :disabled="updatedMetricStatsPreset === null || metricStatsPresetsListIsUpdating"
                @click="savePreset">
        <template v-if="metricStatsPresetsListIsUpdating === false">Save</template>
        <template v-else>Saving <b-spinner label="Loading..." variant="light" small/></template>

      </b-button>
    </div>

  </b-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {pick} from "lodash";
import {BButton, BCol, BRow} from "bootstrap-vue-3";
import Draggable from 'vuedraggable'
import MetricPresetMixin from "@/views/stats/facebook/mixins/metricPreset";

export default defineComponent({
  name: "Columns",
  mixins: [
    MetricPresetMixin,
  ],

  components: {
    BButton,
    BCol,
    BRow,
    Draggable,
  },

  data(): any {
    return {
      list: {
        enabled: [],
        disabled: [],
      }
    };
  },


  computed: {
    ...mapGetters({
      modal: 'stats/facebook/modalColumns',
      updatedMetricStatsPresetsList: 'automation/metricStats/presets/updatedList',
      metricStatsPresetsListIsUpdating: 'automation/metricStats/presets/listIsUpdating',
    }),

    isVisible: {
      get(): boolean {
        return this.modal.isVisible
      },
      set(value) {
        this.updateModalProperty('isVisible', value)
      },
    },

    updatedMetricStatsPreset(): null|Record<string, any> {
      if(this.presetPathParts.entity === null) return null;
      return this.updatedMetricStatsPresetsList?.[this.presetPathParts.preset]?.[this.presetPathParts.entity] ?? null;
    },

    metricStatsPresetDisabled(): null|Record<string, any> {
      if(
        this.presetPathParts.entity === null ||
        this.metricStatsPreset === null ||
        this.metricStatsGlossaryPreset === null
      ) return null;

      const enabledKeys = Object.keys(this.updatedMetricStatsPreset ?? this.metricStatsPreset);
      const disabledKeys = Object.keys(this.metricStatsGlossaryPreset).filter(x => !enabledKeys.includes(x));

      return pick(this.metricStatsGlossaryPreset, disabledKeys);
    },
  },

  methods: {
    ...mapMutations({
      updateModal: 'stats/facebook/updateModal',
      updateMetricStatsPreset: 'automation/metricStats/presets/updatePreset',
    }),
    ...mapActions({
       saveMetricStatsPreset: 'automation/metricStats/presets/updatePreset',
    }),

    updateModalProperty(key: string, value: any) {
      this.updateModal({
        columns: {
          [key]: value,
        }
      })
    },

    revertList() {
      if(this.presetPathParts.entity === null) return null;

      // "unset" updated preset, so that "current" "user defined" one is reapplied
      this.updateMetricStatsPreset({
        preset: this.presetPathParts.preset,
        entity: this.presetPathParts.entity,
        list: null,
      });

      this.initLists();
    },

    init() {
      this.updateModal({columns: {isLoading: true}});
      Promise.all([
        this.metricsGlossaryFetch(),
        this.metricStatsPresetsGlossaryFetch(),
        this.metricStatsPresetsFetch(),
      ]).then(() => {
        this.initLists();
        this.updateModal({columns: {isLoading: false}});
      });
    },

    initLists() {
      this.initEnabledList();
      this.initDisabledList();
    },

    initEnabledList() {
      const metricStatsPreset = this.updatedMetricStatsPreset ?? this.metricStatsPreset;
      if(metricStatsPreset === null) {
        this.list.enabled = [];
        return;
      }
      // this.metricsGlossary
      this.list.enabled = Object.entries(metricStatsPreset).map((item: any[]) => {
        const key = item[0];
        const data = item[1];

        const metricType = data.metric ?? null;
        const metric = (metricType && metricType in this.metricsGlossary)
          ? this.metricsGlossary[metricType]
          : {};

        const label = metric?.labels?.datagrid ?? metric?.labels?.short ?? metric?.labels?.default ?? key;

        return {
          key,
          label,
          data,
        };
      }).sort(function(elementA, elementB) {
        const elementAIndex = elementA.data?.datagrid?.index ?? 0;
        const elementBIndex = elementB.data?.datagrid?.index ?? 0;
        if(elementAIndex === elementBIndex) return 0;
        return (elementAIndex < elementBIndex) ? -1 : 1;
      });
    },

    initDisabledList() {
      if(this.metricStatsPresetDisabled === null) {
        this.list.disabled = [];
        return;
      }
      this.list.disabled = Object.entries(this.metricStatsPresetDisabled).map((item: any[]) => {
        const key = item[0];
        const data = item[1];

        const metricType = data.metric ?? null;
        const metric = (metricType && metricType in this.metricsGlossary)
          ? this.metricsGlossary[metricType]
          : {};

        const label = metric?.labels?.datagrid ?? metric?.labels?.short ?? metric?.labels?.default ?? key;

        return {
          key,
          label,
          data,
        };
      });
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChangeEnabledList(event) {
      const updatedPreset = this.list.enabled.reduce(function(result, item, index) {
        result[item.key] = {
          ...item.data,
          // preserve only updated index in datagrid attribute
          datagrid: { index }
        };

        return result;
      }, {});

      this.updateMetricStatsPreset({
        preset: this.presetPathParts.preset,
        entity: this.presetPathParts.entity,
        list: updatedPreset,
      });
    },

    resetList() {
      if(!this.metricStatsGlossaryPreset) return;

      const defaultList = Object.keys(this.metricStatsGlossaryPreset)
      .filter(key => (this.metricStatsGlossaryPreset[key]?.datagrid?.enabled ?? true))
      .reduce((result, key) => {
        result[key] = {...this.metricStatsGlossaryPreset[key]};
        return result;
      }, {});

      this.updateMetricStatsPreset({
        preset: this.presetPathParts.preset,
        entity: this.presetPathParts.entity,
        list: defaultList,
      });

      this.initLists();
    },

    savePreset() {
      this.saveMetricStatsPreset(this.presetPathParts);
    }
  },

  mounted() {
    if(!this.modal.isVisible) return;
    if(this.modal.isLoading) return;

    this.init();
  },

  watch: {
    'modal.isVisible'(isVisible: boolean) {
      if(!isVisible) return;
      if(this.modal.isLoading) return;

      this.init();
    },

  },
});
</script>

<style scoped>

</style>