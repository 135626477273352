
import {defineComponent} from "vue";

export default defineComponent({
  name: "EffectiveStatus",

  props: {
    status: {
      type: String,
      required: true,
    }
  },

  computed: {
  }
});
