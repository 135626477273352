import {mapActions, mapGetters} from "vuex";
import {entities as datagridEntities} from "@/store/enums/stats/facecebook/Datagrids";
import {defineComponent} from "vue";

export default defineComponent({
  computed: {
    ...mapGetters({
      selectedDatagrid: 'stats/facebook/selectedDatagrid',
      metricStatsPresetsGlossaryList: 'automation/glossary/metricStats/presets/list',
      metricsGlossary: 'automation/glossary/metrics/list',
      metricPreset: 'stats/facebook/metricPreset',
      metricStatsPresetsList: 'automation/metricStats/presets/list',
    }),

    presetPathParts(): Record<string, any> {
      const preset = this.metricPreset ?? 'performance';
      const entity = this.getDatagridEntity(this.selectedDatagrid);
      return {preset, entity};
    },

    metricStatsGlossaryPreset(): null|Record<string, any> {
      if (this.presetPathParts.entity === null || this.metricStatsPreset === null) return null;
      return this.metricStatsPresetsGlossaryList?.[this.presetPathParts.preset]?.[this.presetPathParts.entity] ?? null;
    },

    metricStatsPreset(): null|Record<string, any> {
      if(this.presetPathParts.entity === null) return null;
      return this.metricStatsPresetsList?.[this.presetPathParts.preset]?.[this.presetPathParts.entity] ?? null;
    },
  },

  methods: {
    ...mapActions({
      metricStatsPresetsFetch: 'automation/metricStats/presets/fetch',
      metricStatsPresetsGlossaryFetch: 'automation/glossary/metricStats/presets/fetch',
      metricsGlossaryFetch: 'automation/glossary/metrics/fetch',
    }),

    getDatagridEntity(datagrid: null|string): null|string {
      if(!datagrid || !(datagrid in datagridEntities)) return null;
      return datagridEntities[datagrid];
    },
  },
});