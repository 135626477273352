<template>
  <i class="me-2 opacity-50 fs-9 fas fa-circle" :class="status === 'ACTIVE' ? 'text-success' : 'text-danger'"></i>
  <span style="text-transform: capitalize;">{{ status.toLowerCase().replace('_', ' ') }}</span>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "EffectiveStatus",

  props: {
    status: {
      type: String,
      required: true,
    }
  },

  computed: {
  }
});
</script>

<style scoped>

</style>