<template>
  <b-input-group>
    <b-form-select v-model="selectedPeriod"
                   :options="dropdownOptions"
                   text-field="label"
                   value-field="value"/>
  </b-input-group>
  <div class="mt-2" v-if="selectedPeriod === 'custom'">
    <period-range-selector />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters, mapMutations} from "vuex";
import { ListItem } from '@/store/modules/automation/dropdown/tactics/state';
import PeriodRangeSelector from '@/views/stats/facebook/filters/PeriodRangeSelector.vue';

export default defineComponent({
  name: "PeriodSelect",

  components: {
    PeriodRangeSelector
  },

  computed: {
    ...mapGetters({
      // dropdownList: 'automation/dropdown/ruleExecutionResultActions/objectTypes/list',
      // dropdownListIsLoading: 'automation/dropdown/ruleExecutionResultActions/objectTypes/listIsFetching',

      metricPeriod: 'stats/facebook/metricPeriod'
    }),

    dropdownOptions(): ListItem[] {
      // if(this.dropdownListIsLoading) return [];
      // return [{
      //   label: 'All',
      //   value: 'all'
      // }].concat(this.dropdownList);
      return [
        {
          label: 'Today',
          value: 'today'
        },
        {
          label: 'Yesterday',
          value: 'yesterday'
        },
        {
          label: 'Last 7 Days',
          value: 'last_7d'
        },
        {
          label: 'Last 14 Days',
          value: 'last_14d'
        },
        {
          label: 'Last 30 Days',
          value: 'last_30d'
        },
        {
          label: 'Custom',
          value: 'custom'
        },
      ]
    },

    selectedPeriod:  {
      get(): string {
        return this.metricPeriod ?? 'today';
      },
      set(value) {
        this.setMetricPeriod((value !== 'none') ? value : null);
      }
    }
  },

  methods: {
    // ...mapActions({
    //   fetchDropdownList: 'automation/dropdown/ruleExecutionResultActions/objectTypes/fetch',
    // }),
    ...mapMutations({
      setMetricPeriod: 'stats/facebook/setMetricPeriod'
    }),
  },

  mounted() {
    // this.fetchDropdownList();
  }
});
</script>

<style scoped>

</style>