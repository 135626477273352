<template>
  <kt-datatable :current-page="listPaginationCurrentPage"
                :loading="listIsFetching"
                :rows-per-page="listPaginationItemsPerPage"
                :total="listPaginationTotalRecordsCount"
                :table-data="list || []"
                :table-header="datagridColumns"
                :sort-column-name="listSortColumnName"
                :sort-order="listSortOrder"
                v-on:current-change="onDatagridPaginationCurrentPageChange"
                v-on:items-per-page-change="onDatagridPaginationItemsPerPageChange"
                v-on:sort="onDatagridSortingChange"
  >
    <template v-slot:cell-id="{ row: row }">
      <b-form-checkbox :value="row.id"
                       v-model="selectedIds"/>
    </template>

    <template v-slot:cell-delivery="{ row: row }">
      <effective-status v-if="row.effective_status || null" :status="row.effective_status"/>
    </template>

    <template v-for="loadedMetric in loadedMetrics"
              v-slot:[loadedMetric.slot]="{ row: row }"
              :key="`campaign-metric-column-${row.id}-${loadedMetric.alias}`">
      <component :is="loadedMetric.component ? loadedMetric.component : BaseMetricValue"
                 :metric-value-identifiers="metricValueIdentifiers"
                 :row="row"
                 :alias="loadedMetric.alias"
                 v-on:click="onMetricValueClick(loadedMetric.alias, row)" />
    </template>


  </kt-datatable>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import BaseMetricValue from "@/views/stats/metrics/BaseMetricValue.vue";
import EffectiveStatus from "@/views/stats/facebook/datatables/columns/EffectiveStatus.vue";
import MetricFacebookAlgomaPixelPurchasesCount from "@/views/stats/metrics/facebook/AlgomaPixelPurchasesCount.vue";

import DatatableMixin from "@/views/stats/facebook/mixins/datatable";

const metricComponents = {
  ordersCount: MetricFacebookAlgomaPixelPurchasesCount,
};

export default defineComponent({
  name: "Campaigns",

  extends: DatatableMixin,

  components: {
    KtDatatable,
    BaseMetricValue,
    EffectiveStatus,
    MetricFacebookAlgomaPixelPurchasesCount,
  },

  data() {
    return {
      datagrid: {
        columns: [
          {
            name: null,
            key: 'id',
            sortable: false
          },
          {
            name: 'Campaign Name',
            key: 'name',
            sortable: true,
          },
          {
            name: 'Delivery',
            key: 'effective_status',
            columnKey: 'delivery',
            sortable: true,
          },
          {
            name: 'Objective',
            key: 'objective',
            sortable: true,
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      listIsFetching: 'stats/facebook/campaigns/listIsFetching',
      list: 'stats/facebook/campaigns/list',
      listPaginationTotalRecordsCount: 'stats/facebook/campaigns/listPaginationTotalRecordsCount',
      getListPaginationCurrentPage: 'stats/facebook/campaigns/listPaginationCurrentPage',
      getListPaginationItemsPerPage: 'stats/facebook/campaigns/listPaginationItemsPerPage',
      getListSortColumnName: 'stats/facebook/campaigns/listSortColumnName',
      getListSortMetric: 'stats/facebook/campaigns/listSortMetric',
      getListSortOrder: 'stats/facebook/campaigns/listSortOrder',
      metricValueIdentifiers: 'stats/facebook/campaigns/metricValueIdentifiers',
      selected: 'stats/facebook/campaigns/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'stats/facebook/campaigns/fetch',
    }),

    ...mapMutations({
      setListPaginationCurrentPage: 'stats/facebook/campaigns/setListPaginationCurrentPage',
      setListPaginationItemsPerPage: 'stats/facebook/campaigns/setListPaginationItemsPerPage',
      setListSortColumnName: 'stats/facebook/campaigns/setListSortColumnName',
      setListSortMetric: 'stats/facebook/campaigns/setListSortMetric',
      setListSortOrder: 'stats/facebook/campaigns/setListSortOrder',
      setSelected: 'stats/facebook/campaigns/setSelected',
    }),

    getDepenantDatagrids() {
      return ['adSets', 'ads']
    },

    getMetricComponents() {
      return metricComponents;
    },

    onMetricOrdersCountValueClick(row) {
      this.updateModal({
        shopify: {
          orders: {
            isVisible: true,
            apiUrl: `/automation/metric-stats/shopify/order/by-facebook-campaign/${row.id}`,
            title: `${row.name}`
          },
        },
      });
    },
  },
});
</script>

<style scoped>

</style>