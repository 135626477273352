
import {defineComponent} from "vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import dayjs from "dayjs";
import BaseMetricValue from "@/views/stats/metrics/BaseMetricValue.vue";
import EffectiveStatus from "@/views/stats/facebook/datatables/columns/EffectiveStatus.vue";
import MetricFacebookAlgomaPixelPurchasesCount from "@/views/stats/metrics/facebook/AlgomaPixelPurchasesCount.vue";
import DatatableMixin from "@/views/stats/facebook/mixins/datatable";

const metricComponents = {
  ordersCount: MetricFacebookAlgomaPixelPurchasesCount,
};

export default defineComponent({
  name: "AdSets",

  extends: DatatableMixin,

  components: {
    KtDatatable,
    BaseMetricValue,
    MetricFacebookAlgomaPixelPurchasesCount,
    EffectiveStatus
  },

  data() {
    return {
      datagrid: {
        columns: [
          {
            name: null,
            key: 'id',
            sortable: false,
          },
          {
            name: 'AdSet Name',
            key: 'name',
            sortable: true,
          },
          {
            name: 'Delivery',
            key: 'effective_status',
            columnKey: 'delivery',
            sortable: true,
          },
        ],
      },

      metricComponents: {
        ordersCount: MetricFacebookAlgomaPixelPurchasesCount,
      }
    };
  },

  computed: {
    ...mapGetters({
      listIsFetching: 'stats/facebook/adSets/listIsFetching',
      list: 'stats/facebook/adSets/list',
      listPaginationTotalRecordsCount: 'stats/facebook/adSets/listPaginationTotalRecordsCount',
      getListPaginationCurrentPage: 'stats/facebook/adSets/listPaginationCurrentPage',
      getListPaginationItemsPerPage: 'stats/facebook/adSets/listPaginationItemsPerPage',
      getListSortColumnName: 'stats/facebook/adSets/listSortColumnName',
      getListSortMetric: 'stats/facebook/adSets/listSortMetric',
      getListSortOrder: 'stats/facebook/adSets/listSortOrder',
      metricValueIdentifiers: 'stats/facebook/adSets/metricValueIdentifiers',
      selected: 'stats/facebook/adSets/selected',
    }),
  },


  methods: {
    ...mapActions({
      fetchStats: 'stats/facebook/adSets/fetch',
    }),

    ...mapMutations({
      setListPaginationCurrentPage: 'stats/facebook/adSets/setListPaginationCurrentPage',
      setListPaginationItemsPerPage: 'stats/facebook/adSets/setListPaginationItemsPerPage',
      setListSortColumnName: 'stats/facebook/adSets/setListSortColumnName',
      setListSortMetric: 'stats/facebook/adSets/setListSortMetric',
      setListSortOrder: 'stats/facebook/adSets/setListSortOrder',
      setSelected: 'stats/facebook/adSets/setSelected',
    }),

    getDepenantDatagrids() {
      return ['ads']
    },

    getMetricComponents() {
      return metricComponents;
    },

    onMetricOrdersCountValueClick(row) {
      this.updateModal({
        shopify: {
          orders: {
            isVisible: true,
            apiUrl: `/automation/metric-stats/shopify/order/by-facebook-adset/${row.id}`,
            title: `${row.name}`
          },
        },
      });
    }
  },
});
