
import {defineComponent} from "vue";
import Filters from "@/core/filters";

export default defineComponent({
  name: "BaseMetricValue",

  emits: [
    "click", // we won't emmit it, but we should have it for consistency
  ],

  props: {
    alias: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    metricValueIdentifiers: {
      type: Object,
      required: true,
    }
  },

  computed: {
    value() {
      if(!this.metricValueIdentifiers) return null;

      const metricIdentifier = (this.alias in this.metricValueIdentifiers) ? this.metricValueIdentifiers[this.alias] : null;
      if(!metricIdentifier) return null;

      const metricsData: any[] = this.row?.metrics_data ?? [];
      const metricData: null|Record<any, any> = metricsData.find(item => item.valueIdentifier === metricIdentifier.valueIdentifier);

      if(!metricData) return null;

      return metricData.value ? Filters.number({value: Number(metricData.value)}) : null;
    },
  }
});
