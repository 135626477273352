<template>
  <base-filter v-on:clear="clear"
               v-on:remove="remove">
    <template v-slot:filter>
      <b-form-tags :input-id="inputId"
                   v-model="value"
                   tag-variant="primary"
                   size="md"
                   remove-on-delete
                   :placeholder="placeholder"
                   add-on-change
                   no-outer-focus>
        <template v-slot="{ tags, tagClass, tagVariant, tagPills, removeTag, inputAttrs, inputHandlers, disabled, computedId }">
          <ul :id="`${computedId}tag_list__`"
              class="b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center">
            <b-form-tag v-for="tag in tags"
                        :key="tag"
                        :title="tag"
                        :class="tagClass"
                        tag="li"
                        :variant="tagVariant"
                        :pill="tagPills"
                        @remove="removeTag" >
              <div style="width: 0;height:0;padding:0;margin:0;color:transparent;">{{tag}}</div>
              <!-- this weird structure is needed for b-form-tag to get "correct" tag text on delete -->
              <div>{{ getOptionLabel(tag) }}</div>
            </b-form-tag>
            <li role="none"
                aria-live="off"
                class="b-from-tags-field flex-grow-1"
                :aria-controls="`${computedId}tag_list__`">
              <div role="group" class="d-flex">
                <b-form-select v-bind="inputAttrs"
                               v-on="inputHandlers"
                               size="sm"
                               :disabled="disabled || availableOptions.length === 0"
                               :options="availableOptions"
                               text-field="label"
                               value-field="value">
                  <template #first>
                    <!-- This is required to prevent bugs with Safari -->
                    <option disabled value="">{{ placeholder }}</option>
                  </template>
                </b-form-select>

              </div>
            </li>
          </ul>
        </template>
      </b-form-tags>
    </template>
  </base-filter>

</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseFilter from '@/views/stats/facebook/filters/dynamic/BaseFilter.vue';
import {mapGetters, mapMutations} from "vuex";
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
export default defineComponent({
  name: "OptionsFilter",

  props: {
    filterKey: {
      type: String,
      required: true,
    },
  },

  components: {
    BaseFilter
  },

  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
    }),

    filter():FilterItemPayload {
      return this.scopeFilters.find(filter => filter.key === this.filterKey);
    },

    value: {
      get(): string {
        return this.filter.value ?? [];
      },
      set(value) {
        this.setScopeFilter({
          key: this.filter.key,
          value: value
        });
      }
    },

    placeholder() {
      return this.filter.settings?.gui?.inputPlaceholder ?? 'Options';
    },

    inputId() {
      return this.filter.key;
    },

    availableOptions() {
      const options = this.filter.settings?.gui?.inputOptions ?? [];
      return options.filter(option => this.value.indexOf(option.value) === -1)
    },
  },

  methods: {
    ...mapMutations({
      setScopeFilter: 'stats/facebook/setScopeFilter',
      removeScopeFilter: 'stats/facebook/removeScopeFilter',
    }),
    clear() {
      this.setScopeFilter({key: this.filterKey, value: null});
    },
    remove() {
      this.removeScopeFilter(this.filterKey);
    },

    getOptionLabel(optionValue) {
      const options = this.filter.settings?.gui?.inputOptions ?? [];
      const option = options.find(option => option.value === optionValue);

      return option?.label ?? optionValue;
    },

  }
});
</script>

<style scoped>
  select.form-select {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.45rem;
    border: none;
  }
</style>