
import {defineComponent} from "vue";
import {
  FilterItemSettings,
  FilterGroup,
  FilterGroups,
  FilterItemPayload
} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
import {mapGetters, mapMutations} from "vuex";
import {BDropdownText} from "bootstrap-vue-3";


export default defineComponent({
  name: "FilterSelector",
  components: {BDropdownText},
  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
      scopeDynamicFilters: 'stats/facebook/scopeDynamicFilters'
    }),
    filterOptions(): FilterGroups {

      const scopeFilterKeys = this.scopeFilters?.map((item: FilterItemPayload) => item.key) ?? [];
      return this.scopeDynamicFilters
        .map((filterGroup: FilterGroup) => {
          const filterGroupClone = {...filterGroup};
          filterGroupClone.list = filterGroup.list.filter((item: FilterItemSettings) => {
            return !scopeFilterKeys.includes(item.gui?.filterKey);
          });

          return filterGroupClone;
        })
        .filter(filterGroup => filterGroup.list.length !== 0);
    }
  },

  methods: {
    ...mapMutations({
      setScopeFilter: 'stats/facebook/setScopeFilter'
    }),
    selectFilter(filter) {
      this.setScopeFilter({
        key: filter.gui?.filterKey,
        settings: filter,
        value: null
      });
    }
  }
});
