
import {defineComponent} from "vue";
export default defineComponent({
  name: "BaseFilter",

  emits: ['clear', 'remove'],

  methods: {
    clear() {
      this.$emit('clear');
    },
    remove() {
      this.$emit('remove');
    }
  }

});
