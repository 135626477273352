
import {defineComponent} from "vue";
import PeriodSelector from "@/views/stats/facebook/filters/PeriodSelector.vue";
import FilterSelector from "@/views/stats/facebook/filters/FilterSelector.vue";
import {mapGetters} from "vuex";
import {BButton, BRow} from "bootstrap-vue-3";
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
import FilterStrings from "@/views/stats/facebook/filters/dynamic/Strings.vue";
import FilterNumbers from "@/views/stats/facebook/filters/dynamic/Numbers.vue";
import FilterOptions from "@/views/stats/facebook/filters/dynamic/Options.vue";
import FilterIds from "@/views/stats/facebook/filters/dynamic/Ids.vue";

export default defineComponent({
  name: "Filters",

  emits: ["fetch", "reset", "openColumnsModal"],
  components: {
    BRow,
    BButton,
    PeriodSelector,
    FilterSelector
  },

  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
    }),
  },

  methods: {
    reset() {
      this.$emit('reset');
    },
    fetch() {
      this.$emit('fetch');
    },
    openColumnsModal() {
      this.$emit('openColumnsModal');
    },
    getScopeFilterComponent(scopeFilter: FilterItemPayload)
    {
      const defaultFilterType = 'strings';
      let filterType = scopeFilter.settings?.gui?.inputType ?? defaultFilterType;
      const components = {
        strings: FilterStrings,
        numbers: FilterNumbers,
        options: FilterOptions,
        ids: FilterIds,
      }

      if(!(filterType in components)) filterType = defaultFilterType;

      return components[filterType]
    },
  }

});
