<template>
  <div class="input-group">
    <div class="input-group-prepend">

      <b-dropdown size="md"
                  dropdown
                  variant="secondary"
                  class="h-100"
                  toggle-class="text-decoration-none px-0"
                  no-caret>
        <template v-slot:button-content>
          <i class="fas fa-ellipsis-h ms-1" />
        </template>

        <b-dropdown-item-button v-on:click="clear">
          <i class="fas fa-recycle w-15px text-success" /> clear
        </b-dropdown-item-button>
        <b-dropdown-item-button v-on:click="remove">
          <i class="fas fa-times w-15px text-danger" /> remove
        </b-dropdown-item-button>
      </b-dropdown>

      <slot name="prepend" />
    </div>

    <slot name="filter">
      -- not implemented --
    </slot>

    <div class="input-group-append" v-if="!!$slots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
  name: "BaseFilter",

  emits: ['clear', 'remove'],

  methods: {
    clear() {
      this.$emit('clear');
    },
    remove() {
      this.$emit('remove');
    }
  }

});
</script>

<style scoped>

</style>