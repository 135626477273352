import {defineComponent} from "vue";
import {merge} from "lodash";

export default defineComponent({
  methods: {
    /**
     * Retrieves parameters from url and sets correct filters
     */
    getFiltersFromUrl: function (urlString = null): Record<any, any> {
      let url = urlString || window.location.hash;
      let data = {};

      // Return data if url length is not more than 1
      if (url.length <= 1) {
        return data;
      }

      // Remove # as we do not need it in the string
      if (url[0] === '#') {
        url = url.substring(1);
      }

      try{
        data = JSON.parse(atob(url));
        // eslint-disable-next-line no-empty
      } catch(error) {
        console.error('getFiltersFromUrl', error);
      }

      return data;
    },

    /**
     * Adds parameters to url
     */
    setUrlHash: function (data) {
      window.location.hash = this.calculateUrlHash(data);
    },

    updateUrlHash: function (data) {
      const currentFilters = this.getFiltersFromUrl();
      this.setUrlHash(merge(currentFilters, data));
    },

    calculateUrlHash: function (data) {
      return btoa(JSON.stringify(data));
    }
  }
});
