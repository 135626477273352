<template>
  <base-metric-value :metric-value-identifiers="metricValueIdentifiers"
                     :row="row"
                     :alias="alias">
    <template v-slot:value="{ value: value }">
      <a v-if="value && value > 0" v-on:click="onClick" class="order-count text-primary cursor-pointer">
        {{value}}
      </a>
    </template>
  </base-metric-value>
</template>

<script>
import BaseMetricValue from "@/views/stats/metrics/BaseMetricValue.vue";

export default {
  name: "AlgomaPixelPurchasesCount",

  emits: ["click"],

  props: {
    alias: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    metricValueIdentifiers: {
      type: Object,
      required: true,
    }
  },

  components: {
    BaseMetricValue,
  },

  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
a.order-count {
  padding: 8px;
}
a.order-count:hover {
  border-bottom: 1px solid;
}
</style>