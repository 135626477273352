<template>
  <base-filter v-on:clear="clear"
               v-on:remove="remove">
    <template v-slot:filter>
      <b-form-tags :input-id="inputId"
                   v-model="value"
                   tag-variant="primary"
                   tag-pills
                   size="md"
                   separator=" ,;"
                   remove-on-delete
                   :placeholder="placeholder"
                   invalid-tag-text="Invalid Number(s)"
                   duplicate-tag-text="Duplicate Number(s)"
                   :tag-validator="validator"/>
    </template>

    <template v-slot:append>
      <b-dropdown size="md"
                  dropdown
                  variant="secondary"
                  class="h-100"
                  toggle-class="text-decoration-none ps-2 pe-1"
                  no-caret>
        <template v-slot:button-content>
          <i v-if="selectedOperatorOption"
             :class="['fas', selectedOperatorOption.icon]"
             :title="selectedOperatorOption.title" />
        </template>

        <b-dropdown-item-button v-for="(operatorOption, operatorOptionIndex) in operatorOptions"
                                :key="`operator-option-${operatorOptionIndex}`"
                                v-on:click="selectOperatorOption(operatorOption)">
          <i :class="['fas', operatorOption.icon]" :title="operatorOption.title" />
        </b-dropdown-item-button>
      </b-dropdown>
    </template>
  </base-filter>

</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseFilter from '@/views/stats/facebook/filters/dynamic/BaseFilter.vue';
import {mapGetters, mapMutations} from "vuex";
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";

type DataPayload = {
  selectedOperatorOption: null|Record<string, any>,
  operatorOptions: Record<string, any>[],
}

export default defineComponent({
  name: "NumbersFilter",

  props: {
    filterKey: {
      type: String,
      required: true,
    },
  },

  components: {
    BaseFilter
  },

  data(): DataPayload {
    return {
      selectedOperatorOption: null,
      operatorOptions: [
        {value: 'equal', title: 'Equal', icon: 'fa-equals'},
        {value: 'not_equal', title: 'Not Equal', icon: 'fa-not-equal'},
        {value: 'greater_than', title: 'Greater Than', icon: 'fa-greater-than'},
        {value: 'less_than', title: 'Less Than', icon: 'fa-less-than'},
        {value: 'greater_than_or_equal', title: 'Greater Than or Equal', icon: 'fa-greater-than-equal'},
        {value: 'less_than_or_equal', title: 'Less Than or Equal', icon: 'fa-less-than-equal'},
      ],
    }
  },

  mounted() {
    if(!this.selectedOperatorOption) {
      this.selectedOperatorOption = this.operatorOptions.find(option => option.value === 'equal') ?? null;
    }
  },

  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
    }),

    filter():FilterItemPayload {
      return this.scopeFilters.find(filter => filter.key === this.filterKey);
    },

    value: {
      get(): string {
        return this.filter.value ?? [];
      },
      set(value) {
        this.setScopeFilter({
          key: this.filter.key,
          value: value
        });
      }
    },

    placeholder() {
      return this.filter.settings?.gui?.inputPlaceholder ?? 'Value';
    },

    inputId() {
      return this.filter.key;
    },
  },

  methods: {
    ...mapMutations({
      setScopeFilter: 'stats/facebook/setScopeFilter',
      removeScopeFilter: 'stats/facebook/removeScopeFilter',
    }),
    clear() {
      this.setScopeFilter({key: this.filterKey, value: null});
    },
    remove() {
      this.removeScopeFilter(this.filterKey);
    },
    selectOperatorOption(operatorOption) {
      this.selectedOperatorOption = operatorOption;
      // this.filter = {...this.filter, operator: operatorOption.value};

      this.setScopeFilter({
        key: this.filter.key,
        settings: {
          api: {
            operator: operatorOption.value
          }
        }
      });
    },

    isNumeric(num){
      return !isNaN(num)
    },
    validator(value) {
      return this.isNumeric(value);
    }
  }
});
</script>

<style scoped>

</style>