<template>
  <b-card class="p-0">
    <filters v-on:fetch="fetchList"
             v-on:reset="resetListFilters"
             v-on:openColumnsModal="openColumnsModal"/>

    <b-tabs class="mt-3"
            content-class="mt-3"
            nav-class="fs-5 fw-bold"
            align="center"
            justified
            lazy
            v-model="tabIndex">
      <b-tab>
        <template v-slot:title>
          <i class="fas fa-folder fs-4 me-3" />
          <span class="fw-bolder text-gray-700">{{ getDatagridName('campaigns') }}</span>
          <b-badge v-if="selectedCampaigns.length > 0"
                   class="ms-3"
                   variant="primary"
                   pill>
            <i class="fas fa-times me-1 text-light" v-on:click.prevent="clearSelected('campaigns')" />
            {{selectedCampaigns.length}} Selected
          </b-badge>
        </template>
        <datatable-campaigns />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <i class="fas fa-th-large fs-4 me-3" />
          <span class="fw-bolder text-gray-700">{{ getDatagridName('adSets') }}</span>
          <b-badge v-if="selectedAdSets.length > 0"
                   class="ms-3"
                   variant="primary"
                   pill>
            <i class="fas fa-times me-1 text-light" v-on:click.prevent="clearSelected('adSets')" />
            {{selectedAdSets.length}} Selected
          </b-badge>
        </template>
        <datatable-ad-sets />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <i class="fas fa-portrait fs-4 me-3" />
          <span class="fw-bolder text-gray-700">{{ getDatagridName('ads') }}</span>
          <b-badge v-if="selectedAds.length > 0"
                   class="ms-3"
                   variant="primary"
                   pill>
            <i class="fas fa-times me-1 text-light" v-on:click.prevent="clearSelected('ads')" />
            {{selectedAds.length}} Selected
          </b-badge>
        </template>
        <datatable-ads />
      </b-tab>
    </b-tabs>

    <modal-shopify-orders />
    <modal-columns />
  </b-card>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Filters from "@/views/stats/facebook/Filters.vue";
import DatatableCampaigns from "@/views/stats/facebook/datatables/Campaigns.vue";
import DatatableAdSets from "@/views/stats/facebook/datatables/AdSets.vue";
import DatatableAds from "@/views/stats/facebook/datatables/Ads.vue";
import ModalShopifyOrders from "@/views/stats/facebook/modal/shopify/Orders.vue";
import ModalColumns from "@/views/stats/facebook/modal/Columns.vue";
import {labels as datagridNames} from "@/store/enums/stats/facecebook/Datagrids";
import urlHashParametersEncodedMixin from '@/views/mixins/urlHashParametersEncoded';
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";

export default defineComponent({
  name: "Facebook",

  mixins: [
    urlHashParametersEncodedMixin
  ],

  components: {
    Filters,
    DatatableCampaigns,
    DatatableAdSets,
    DatatableAds,
    ModalShopifyOrders,
    ModalColumns
  },

  created() {
    const urlParams = this.getFiltersFromUrl();

    if(urlParams?.datagrid) {
      this.selectDatagrid(urlParams?.datagrid)
    }
    if(urlParams?.metric?.period) {
      const metricPeriod = (urlParams.metric.period !== 'none') ? urlParams.metric.period : null;
      this.setMetricPeriod(metricPeriod);
    }
    if(urlParams?.metric?.periodRange) {
      this.setMetricPeriodRange(urlParams.metric.periodRange);
    }
    if(urlParams?.scope?.filters) {
      this.setScopeFilters(this.decodeQueryScopeFilters(urlParams.scope.filters));
    }
  },

  mounted() {
    setCurrentPageTitle("Ads Stats");

    Promise.all([
      this.metricsGlossaryFetch(),
      this.metricStatsPresetsGlossaryFetch(),
      this.metricStatsPresetsFetch(),
    ])
  },

  data() {
    return {
      datagridTabIndexes: {
        campaigns: 0,
        adSets: 1,
        ads: 2,
      }
    };
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
      selectedDatagrid: 'stats/facebook/selectedDatagrid',
      selectedCampaigns: 'stats/facebook/campaigns/selected',
      selectedAdSets: 'stats/facebook/adSets/selected',
      selectedAds: 'stats/facebook/ads/selected',
      scopeFilters: 'stats/facebook/scopeFilters',
      scopeDynamicFilters: 'stats/facebook/scopeDynamicFilters',
      metricPeriod: 'stats/facebook/metricPeriod',
      metricPeriodRange: 'stats/facebook/metricPeriodRange'
    }),

    tabIndex: {
      get(): number {
        let tabIndex = -1;
        if(this.selectedDatagrid && this.selectedDatagrid in this.datagridTabIndexes) {
          tabIndex = this.datagridTabIndexes[this.selectedDatagrid];
        }
        return tabIndex;
      },
      set(value) {
        let tabIndex = value ? value : 0;
        if(tabIndex > Object.values(this.datagridTabIndexes)) tabIndex = 0;

        const datagrid = Object.entries(this.datagridTabIndexes).map(item => {
          return {
            name: item[0],
            tabIndex: item[1],
          };
        }).find(item => item.tabIndex === tabIndex);

        this.selectDatagrid(datagrid?.name);
      }
    }
  },

  methods: {
    ...mapActions({
      fetch: "stats/facebook/fetch",
      clearDatagrids: 'stats/facebook/clearDatagrids',

      metricStatsPresetsFetch: 'automation/metricStats/presets/fetch',
      metricStatsPresetsGlossaryFetch: 'automation/glossary/metricStats/presets/fetch',
      metricsGlossaryFetch: 'automation/glossary/metrics/fetch',
    }),
    ...mapMutations({
      setScopeFilters: "stats/facebook/setScopeFilters",
      setMetricPeriod: "stats/facebook/setMetricPeriod",
      setMetricPeriodRange: 'stats/facebook/setMetricPeriodRange',
      selectDatagrid: "stats/facebook/selectDatagrid",
      clearSelectedCampaigns: "stats/facebook/campaigns/clearSelected",
      clearSelectedAdSets: "stats/facebook/adSets/clearSelected",
      clearSelectedAds: "stats/facebook/ads/clearSelected",
      updateModal: 'stats/facebook/updateModal',
    }),

    resetListFilters() {
      this.setScopeFilters(null);
      this.setMetricPeriod('today');
    },

    clearSelected(datagrid) {
      switch (datagrid) {
        case 'campaigns':
          this.clearSelectedCampaigns();
          this.clearDatagrids(['adSets', 'ads']);
          break;
        case 'adSets':
          this.clearSelectedAdSets();
          this.clearDatagrids(['ads']);
          break;
        case 'ads':
          this.clearSelectedAds();
          break;
        default: break;
      }
    },

    getDatagridName(datagrid): string {
      if(!datagrid || !(datagrid in datagridNames)) return '';
      return datagridNames[datagrid];
    },

    openColumnsModal() {
      const datagridName = this.getDatagridName(this.selectedDatagrid);
      const title = `${datagridName} columns`;
      this.updateModal({
        columns: {
          isVisible: true,
          title
        },
      });
    },

    fetchList() {
      // update url hash
      const urlHashData = {
        datagrid: this.selectedDatagrid,
        metric: {
          period: this.metricPeriod,
        },
      };
      if(this.metricPeriod === 'custom') {
        urlHashData.metric['periodRange'] = this.metricPeriodRange;
      }
      if(this.scopeFilters) {
        urlHashData['scope'] = {
          filters: this.encodeScopeFiltersForQuery(),
        };
      }
      this.setUrlHash(urlHashData);

      // fetch list
      this.fetch();
    },

    encodeScopeFiltersForQuery() {
      return this.scopeFilters?.map((filter: FilterItemPayload) => {
        return {
          key: `${filter.settings?.api.entity}|${filter.settings?.gui?.filterKey}`,
          value: filter.value,
          operator: filter.settings?.api.operator ?? null
        }
      }) ?? null;
    },
    decodeQueryScopeFilters(queryScopeFilters: Record<string, any>[]) {
      return queryScopeFilters.map((filterItem) => {
        try{
          const [entity, filterKey] = filterItem.key.split('|');
          const filterGroup = this.scopeDynamicFilters.find(item => item.entity === entity);
          const filterSettings = filterGroup?.list.find(item => item.gui?.filterKey === filterKey);

          if(filterItem?.operator) {
            filterSettings.api.operator = filterItem.operator
          }

          return {
            key: filterSettings.gui?.filterKey,
            settings: filterSettings,
            value: filterItem?.value ?? null,
          }
        } catch (error) {
          console.error('decodeQueryScopeFilters', error);
          return null;
        }
      }).filter(filter => filter !== null);
    },
  },

  watch: {
    'selectedShopifyShop.id'() {
      this.fetchList();
    }
  },

});
</script>

<style>
.nav-link:not(.active) {
  background: #f8f8f8;
}
.nav-item {
  margin-left: 5px;
}
.nav-item:first-child {
  margin-left: 0;
}
</style>