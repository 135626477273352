
import {defineComponent} from "vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import dayjs from "dayjs";
import AdName from "@/views/stats/facebook/datatables/columns/ads/Name.vue";
import EffectiveStatus from "@/views/stats/facebook/datatables/columns/EffectiveStatus.vue";
import BaseMetricValue from "@/views/stats/metrics/BaseMetricValue.vue";
import MetricFacebookAlgomaPixelPurchasesCount from "@/views/stats/metrics/facebook/AlgomaPixelPurchasesCount.vue";
import DatatableMixin from "@/views/stats/facebook/mixins/datatable";

const metricComponents = {
  ordersCount: MetricFacebookAlgomaPixelPurchasesCount,
};


export default defineComponent({
  name: "Ads",

  extends: DatatableMixin,

  components: {
    AdName,
    EffectiveStatus,
    KtDatatable,
    BaseMetricValue,
    MetricFacebookAlgomaPixelPurchasesCount
  },

  data() {
    return {
      datagrid: {
        columns: [
          {
            name: null,
            key: 'id',
            sortable: false,
          },
          {
            name: 'Ad Name',
            key: 'name',
            sortable: true,
          },
          {
            name: 'Delivery',
            key: 'effective_status',
            columnKey: 'delivery',
            sortable: true,
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      listIsFetching: 'stats/facebook/ads/listIsFetching',
      list: 'stats/facebook/ads/list',
      listPaginationTotalRecordsCount: 'stats/facebook/ads/listPaginationTotalRecordsCount',
      getListPaginationCurrentPage: 'stats/facebook/ads/listPaginationCurrentPage',
      getListPaginationItemsPerPage: 'stats/facebook/ads/listPaginationItemsPerPage',
      getListSortColumnName: 'stats/facebook/ads/listSortColumnName',
      getListSortMetric: 'stats/facebook/ads/listSortMetric',
      getListSortOrder: 'stats/facebook/ads/listSortOrder',
      metricValueIdentifiers: 'stats/facebook/ads/metricValueIdentifiers',
      selected: 'stats/facebook/ads/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'stats/facebook/ads/fetch',
    }),

    ...mapMutations({
      setListPaginationCurrentPage: 'stats/facebook/ads/setListPaginationCurrentPage',
      setListPaginationItemsPerPage: 'stats/facebook/ads/setListPaginationItemsPerPage',
      setListSortColumnName: 'stats/facebook/ads/setListSortColumnName',
      setListSortMetric: 'stats/facebook/ads/setListSortMetric',
      setListSortOrder: 'stats/facebook/ads/setListSortOrder',
      setSelected: 'stats/facebook/ads/setSelected',
    }),


    getMetricComponents() {
      return metricComponents;
    },

    onMetricOrdersCountValueClick(row) {
      this.updateModal({
        shopify: {
          orders: {
            isVisible: true,
            apiUrl: `/automation/metric-stats/shopify/order/by-facebook-ad/${row.id}`,
            title: `${row.name}`
          },
        },
      });
    }
  },
});
