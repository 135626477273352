<template>
  <b-row>
    <b-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="8">
      <div class="w-100 d-flex align-items-start flex-wrap gap-2 mb-3"
           v-if="scopeFilters">
        <div v-for="scopeFilter in scopeFilters"
             :key="`dynamic-scope-filter-${scopeFilter.key}`">
          <component :is="getScopeFilterComponent(scopeFilter)"
                     :filter-key="scopeFilter.key"/>
        </div>
      </div>

      <div>
        <filter-selector />
      </div>
    </b-col>


    <b-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="4" class="mt-3 mt-sm-0">
      <div class="w-100 d-flex justify-content-end flex-wrap gap-2 mb-3">
        <div>
          <b-button size="md" @click="openColumnsModal" variant="secondary" title="Columns" class="px-3">
            <i class="fas fa-table fs-1 px-0" />
          </b-button>
        </div>

        <div class="mw-sm-300px mw-100px">
          <period-selector />
        </div>

        <div class="action-buttons d-flex justify-content-end align-items-start flex-wrap gap-2 mb-3">
          <b-button size="md" @click="reset" variant="secondary">Reset</b-button>
          <b-button size="md" @click="fetch" variant="primary">Filter</b-button>
        </div>
      </div>
    </b-col>


  </b-row>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import PeriodSelector from "@/views/stats/facebook/filters/PeriodSelector.vue";
import FilterSelector from "@/views/stats/facebook/filters/FilterSelector.vue";
import {mapGetters} from "vuex";
import {BButton, BRow} from "bootstrap-vue-3";
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
import FilterStrings from "@/views/stats/facebook/filters/dynamic/Strings.vue";
import FilterNumbers from "@/views/stats/facebook/filters/dynamic/Numbers.vue";
import FilterOptions from "@/views/stats/facebook/filters/dynamic/Options.vue";
import FilterIds from "@/views/stats/facebook/filters/dynamic/Ids.vue";

export default defineComponent({
  name: "Filters",

  emits: ["fetch", "reset", "openColumnsModal"],
  components: {
    BRow,
    BButton,
    PeriodSelector,
    FilterSelector
  },

  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
    }),
  },

  methods: {
    reset() {
      this.$emit('reset');
    },
    fetch() {
      this.$emit('fetch');
    },
    openColumnsModal() {
      this.$emit('openColumnsModal');
    },
    getScopeFilterComponent(scopeFilter: FilterItemPayload)
    {
      const defaultFilterType = 'strings';
      let filterType = scopeFilter.settings?.gui?.inputType ?? defaultFilterType;
      const components = {
        strings: FilterStrings,
        numbers: FilterNumbers,
        options: FilterOptions,
        ids: FilterIds,
      }

      if(!(filterType in components)) filterType = defaultFilterType;

      return components[filterType]
    },
  }

});
</script>

<style scoped>

</style>