import {mapActions, mapGetters, mapMutations} from "vuex";

import {defineComponent} from "vue";
import BaseMetricValue from "@/views/stats/metrics/BaseMetricValue.vue";
import dayjs from "dayjs";
import {entities as datagridEntities} from "@/store/enums/stats/facecebook/Datagrids";
import MetricPresetMixin from "@/views/stats/facebook/mixins/metricPreset";
import {merge} from "lodash";

export default defineComponent({
  extends: MetricPresetMixin,

  data() {
    return {
      datagrid: {
        columns: [],
      },
    };
  },

  computed: {
    listIsFetching() {return false},
    list() {return null},
    listPaginationTotalRecordsCount() {return 0},
    getListPaginationCurrentPage() {return 0},
    getListPaginationItemsPerPage() {return 0},
    getListSortColumnName() {return null},
    getListSortMetric() {return null},
    getListSortOrder() {return null},
    metricValueIdentifiers() {return {}},
    selected() {return []},

    listPaginationCurrentPage:  {
      get(): number {
        return this.getListPaginationCurrentPage;
      },
      set(value: number) {
        this.setListPaginationCurrentPage(value);
      }
    },

    listPaginationItemsPerPage:  {
      get(): number {
        return this.getListPaginationItemsPerPage;
      },
      set(value: number) {
        this.setListPaginationItemsPerPage(value);
      }
    },

    listSortColumnName:  {
      get(): string|null {
        return this.getListSortColumnName;
      },
      set(value: string|null) {
        this.setListSortColumnName(value);
      }
    },

    listSortMetric:  {
      get(): string|null {
        return this.getListSortMetric;
      },
      set(value: string|null) {
        this.setListSortMetric(value);
      }
    },

    listSortOrder:  {
      get(): string|null {
        return this.getListSortOrder;
      },
      set(value: string|null) {
        this.setListSortOrder(value);
      }
    },

    selectedIds: {
      get(): number[] {
        return this.selected;
      },
      set(value: number[]) {
        this.setSelected(value);

        const depenantDatagrids = this.getDepenantDatagrids();
        if(depenantDatagrids) {
          this.clearDatagrids(this.getDepenantDatagrids());
        }
      }
    },

    loadedMetrics(): Record<string, any>[] {
      if(!this.metricValueIdentifiers) return [];
      const metricComponents = this.getMetricComponents();
      return Object.entries(this.metricValueIdentifiers).map((item: any[]) => {
        const alias = item[0];
        const metric = item[1];
        const slot = 'cell-'+alias;
        const component = (alias in metricComponents) ? metricComponents[alias] : BaseMetricValue

        return {
          alias,
          valueIdentifier: metric.valueIdentifier,
          slot,
          component,
        };
      });
    },

    metricPresetColumns(): any[] {
      if(this.presetPathParts.entity === null) return [];

      const metrics = this.metricStatsPresetsList?.[this.presetPathParts.preset]?.[this.presetPathParts.entity] ?? null;
      if(metrics === null) return [];

      return Object.entries(metrics)
      .map((item: any[]) => {
        const key = item[0];

        const defaultData = this.metricStatsGlossaryPreset?.[key] ?? {};
        const data = merge({}, defaultData ?? {}, item[1]);

        const metricType = data.metric ?? null;
        const metric = (metricType && metricType in this.metricsGlossary)
          ? this.metricsGlossary[metricType]
          : {};

        const label = metric?.labels?.datagrid ?? metric?.labels?.short ?? metric?.labels?.default ?? key;
        const metricIsSortable = data?.datagrid?.sortable ?? true;

        return {
          name: label,
          key: key,
          columnKey: null,
          sortingField: null,
          sortable: metricIsSortable,

          metadata: data,
        };
      })
      .sort(function(elementA, elementB) {
        const elementAIndex = elementA.metadata?.datagrid?.index ?? 0;
        const elementBIndex = elementB.metadata?.datagrid?.index ?? 0;
        if(elementAIndex === elementBIndex) return 0;
        return (elementAIndex < elementBIndex) ? -1 : 1;
      });
    },

    datagridColumns(): any[] {
      return [
        ...this.datagrid.columns,
        ...this.metricPresetColumns,
      ];
    }
  },

  methods: {
    getMetricComponents() {return {}},
    fetchStats() {},
    setListPaginationCurrentPage(value) {},
    setListPaginationItemsPerPage(value) {},
    setListSortColumnName(value) {},
    setListSortMetric(value) {},
    setListSortOrder(value) {},
    setSelected(value) {},
    onMetricOrdersCountValueClick(row) {},
    getDepenantDatagrids() {return null},

    ...mapActions({
      clearDatagrids: 'stats/facebook/clearDatagrids',
    }),
    ...mapMutations({
      updateModal: 'stats/facebook/updateModal',
    }),

    formatDate(date) {
      if(!date) return null;
      return dayjs(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm');
    },

    fetchList() {
      this.fetchStats();
    },

    getDatagridEntity(datagrid: null|string): null|string {
      if(!datagrid || !(datagrid in datagridEntities)) return null;
      return datagridEntities[datagrid];
    },

    onDatagridPaginationCurrentPageChange(currentPage) {
      this.listPaginationCurrentPage = currentPage;
      this.fetchList();
    },
    onDatagridPaginationItemsPerPageChange(itemsPerPage) {
      this.listPaginationItemsPerPage = itemsPerPage;
      this.fetchList();
    },
    onDatagridSortingChange(sort) {

      let metric = null;
      if(sort.columnName) {
        const metricPresetColumn = this.metricPresetColumns.find(function(item) {
          const itemColumnKey = item.columnKey ?? item.key;
          return itemColumnKey === sort.columnName;
        });
        if(metricPresetColumn) {
          metric = metricPresetColumn?.metadata?.metric;
        }
      }


      this.listSortOrder = sort.order;
      this.listSortColumnName = sort.columnName;
      this.listSortMetric = metric;
      this.fetchList();
    },

    onMetricValueClick(metricAlias, row) {
      if(metricAlias === 'ordersCount') {
        this.onMetricOrdersCountValueClick(row);
      }
    },
  },

  mounted() {
    if(this.list !== null) return;
    this.fetchList();
  },
});