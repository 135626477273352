
import {defineComponent} from "vue";
import BaseFilter from '@/views/stats/facebook/filters/dynamic/BaseFilter.vue';
import {mapGetters, mapMutations} from "vuex";
import {FilterItemPayload} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
export default defineComponent({
  name: "OptionsFilter",

  props: {
    filterKey: {
      type: String,
      required: true,
    },
  },

  components: {
    BaseFilter
  },

  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
    }),

    filter():FilterItemPayload {
      return this.scopeFilters.find(filter => filter.key === this.filterKey);
    },

    value: {
      get(): string {
        return this.filter.value ?? [];
      },
      set(value) {
        this.setScopeFilter({
          key: this.filter.key,
          value: value
        });
      }
    },

    placeholder() {
      return this.filter.settings?.gui?.inputPlaceholder ?? 'Options';
    },

    inputId() {
      return this.filter.key;
    },

    availableOptions() {
      const options = this.filter.settings?.gui?.inputOptions ?? [];
      return options.filter(option => this.value.indexOf(option.value) === -1)
    },
  },

  methods: {
    ...mapMutations({
      setScopeFilter: 'stats/facebook/setScopeFilter',
      removeScopeFilter: 'stats/facebook/removeScopeFilter',
    }),
    clear() {
      this.setScopeFilter({key: this.filterKey, value: null});
    },
    remove() {
      this.removeScopeFilter(this.filterKey);
    },

    getOptionLabel(optionValue) {
      const options = this.filter.settings?.gui?.inputOptions ?? [];
      const option = options.find(option => option.value === optionValue);

      return option?.label ?? optionValue;
    },

  }
});
