<template>
  <!--begin::Modal - View Users-->
  <b-modal v-model="isVisible"
           :hideFooter="true"
           size="lg">
    <template v-slot:title>
      <b-overlay :show="modal.isLoading" rounded="sm" variant="transparent">
      {{ modal.title }} <span class="text-muted fw-bold fs-7">({{datagrid.pagination.totalRecordsCount}} Orders)</span>
      </b-overlay>
    </template>

    <kt-datatable :current-page="datagrid.pagination.currentPage"
                  :loading="modal.isLoading"
                  :rows-per-page="datagrid.pagination.itemsPerPage"
                  :total="datagrid.pagination.totalRecordsCount"
                  :table-data="list || []"
                  :table-header="datagrid.columns"
                  :sort-column-name="datagrid.sort.columnName"
                  :sort-order="datagrid.sort.order"
                  v-on:current-change="onDatagridPaginationCurrentPageChange"
                  v-on:items-per-page-change="onDatagridPaginationItemsPerPageChange"
                  v-on:sort="onDatagridSortingChange">

      <template v-slot:cell-createdAt="{ row: data }">
        {{ formatDate(data.created_at) }}
      </template>

      <template v-slot:cell-orderNumber="{ row: data }">
        <a class="text-hover-primary cursor-pointer" @click="goToOrder(data.id)">#{{ data.order_number }}</a>
        <i class="fas fa-seedling text-success ms-2" title="New Customer Order" v-if="isNewCustomerOrder(data)" />
<!--        <router-link :to="{ name: 'order.journey', params: { id: data.id } }">#{{ data.order_number }}</router-link>-->
      </template>

      <template v-slot:cell-totalPrice="{ row: data }">
        ${{ data.total_price }}
      </template>

    </kt-datatable>
  </b-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters, mapMutations} from "vuex";
import axios, {AxiosResponse} from "axios";
import PopupMessage from "@/core/helpers/PopupMessage";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import qs from "qs";
import dayjs from "dayjs";

export default defineComponent({
  name: "Orders",

  components: {
    KtDatatable
  },

  data() {
    return {
      list: null,
      datagrid: {
        columns: [
          // {
          //   name: 'ID',
          //   key: 'id',
          //   sortable: false
          // },
          {
            name: 'Number',
            key: 'order_number',
            sortable: false,
            columnKey: 'orderNumber',
          },
          {
            name: 'Total Price',
            key: 'total_price',
            sortable: false,
            columnKey: 'totalPrice',
          },
          {
            name: 'Created At',
            key: 'created_at',
            columnKey: 'createdAt',
            sortable: false,
          },
        ],

        sort: {
          columnName: 'createdAt',
          order: 'desc',
        },

        pagination: {
          currentPage: 1,
          itemsPerPage: 10,
          totalRecordsCount: 0,
        },
      },
    };
  },


  computed: {
    ...mapGetters({
      modal: 'stats/facebook/modalShopifyOrders',
      metricPeriod: 'stats/facebook/metricPeriod',
      metricPeriodRange: 'stats/facebook/metricPeriodRange',
    }),

    isVisible: {
      get(): boolean {
        return this.modal.isVisible
      },
      set(value) {
        this.updateModalProperty('isVisible', value)
      },
    },
  },

  methods: {
    ...mapMutations({
      updateModal: 'stats/facebook/updateModal',
    }),

    formatDate(date) {
      if(!date) return null;
      return dayjs(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm');
    },

    isNewCustomerOrder(data) {
      return data?.is_new_customer_order ?? false;
    },

    updateModalProperty(key: string, value: any) {
      this.updateModal({
        shopify: {
          orders: {
            [key]: value,
          },
        }
      })
    },

    fetchOrders() {
      if(!this.modal.apiUrl) return;

      this.updateModalProperty('isLoading', true);

      const paramsSerializer = params => {
        return qs.stringify(params)
      }
      const params = {
        period: this.metricPeriod ?? 'today',
        limit: this.datagrid.pagination.itemsPerPage,
        page: this.datagrid.pagination.currentPage,
      };

      if(params.period === 'custom') {
        if(this.metricPeriodRange) {
          params.period = [
            dayjs(this.metricPeriodRange.from).startOf('day').tz('UTC').format(),
            dayjs(this.metricPeriodRange.to).endOf('day').tz('UTC').format(),
          ]
        } else {
          params.period = 'today';
        }
      }

      axios.get(this.modal.apiUrl, {params, paramsSerializer}).then(async (response: AxiosResponse<any>) => {
        this.list = response.data.data;
        this.datagrid.pagination.totalRecordsCount = response.data.count;
      })
      .catch((error) => {
        console.log(error);
        return PopupMessage.show('Failed to fetch facebook campaign orders stats', 'error', 'Ok')
      })
      .finally(() => {
        this.updateModalProperty('isLoading', false);
      });
    },

    onDatagridPaginationCurrentPageChange(currentPage) {
      this.datagrid.pagination.currentPage = currentPage;
      this.fetchOrders();
    },
    onDatagridPaginationItemsPerPageChange(itemsPerPage) {
      this.datagrid.pagination.itemsPerPage = itemsPerPage;
      this.fetchOrders();
    },
    onDatagridSortingChange(sort) {
      this.datagrid.sort.order = sort.order;
      this.datagrid.sort.columnName = sort.columnName;
      this.fetchOrders();
    },
    goToOrder(orderId) {
      // hiding the modal manually, otherwise the overflow:hidden stays in the body element, making the scrollbar hidden on following screens until, see: https://github.com/bootstrap-vue/bootstrap-vue/issues/1167
      this.updateModalProperty('isVisible', false)
      this.$router.push({
        name: 'order.journey',
        params: { id: orderId }
      })
    }
  },

  watch: {
    'modal.apiUrl'() {
      this.datagrid.pagination.currentPage = 1;
      this.datagrid.pagination.totalRecordsCount = 0;
      this.list = null;
      this.fetchOrders();
    },
    'modal.isVisible'(isVisible: boolean) {
      if(!isVisible) return;
      if(this.modal.isLoading) return;
      if(this.list !== null) return;
      this.fetchOrders();
    },
    metricPeriod() {
      this.datagrid.pagination.currentPage = 1;
      this.datagrid.pagination.totalRecordsCount = 0;
      this.list = null;
    }
  },
});
</script>

<style scoped>

</style>