<template>
  <div class="column-name">
    <div class="me-2 symbol image-wrapper">
      <img v-if="thumbnail" :src="thumbnail" class="opacity-75 image-thumbnail" />
      <i v-else class="far fa-image align-middle image-placeholder" />
    </div>

    {{row.name}}
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "Name",

  props: {
    row: {
      type: Object,
      required: true,
    }
  },

  computed: {
    thumbnail(): null|string {
      return this.row?.facebook_ad_creative?.thumbnail_url ?? null;
    },
  }

});
</script>

<style lang="scss" scoped>
  .column-name {
    .image-wrapper {

      width: 50px;
      height: 50px;

      .image-placeholder {
        font-size: 50px;
      }

      .image-thumbnail {
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
</style>