<template>
  <flat-pickr v-model="date"
              :config="config"
              class="form-control"
              placeholder="Select range"
              name="date"
              @on-change="onChangeFlatPickr" />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import FlatPickr from 'vue-flatpickr-component';
import {mapGetters, mapMutations} from "vuex";

interface IComponentData {
  date: null | Array<Record<string, any>>,
  dateFrom: null | Record<string, any>,
  dateTo: null | Record<string, any>,
  config: {
    wrap: boolean,
    altFormat: string,
    altInput: boolean,
    dateFormat: string,
    mode: string
  },
}

export default defineComponent({
  name: "PeriodRangeSelector",

  components: {
    FlatPickr,
  },

  data(): IComponentData {
    return {
      date: null,
      dateFrom: null,
      dateTo: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        mode: 'range'
      },
    }
  },

  computed: {
    ...mapGetters({
      metricPeriodRange: 'stats/facebook/metricPeriodRange'
    })
  },

  mounted() {
    if(this.metricPeriodRange) this.onChangeMetricPeriodRange(this.metricPeriodRange);
  },

  watch: {
    metricPeriodRange(value) {
      this.onChangeMetricPeriodRange(value);
    }
  },

  methods: {
    ...mapMutations({
      setMetricPeriodRange: 'stats/facebook/setMetricPeriodRange'
    }),

    onChangeFlatPickr(dates) {
      if(!dates || dates.length < 2) return;

      this.dateFrom = dates[0];
      this.dateTo = dates[1];

      this.setMetricPeriodRange({
        from: this.dateFrom,
        to: this.dateTo
      });
    },

    onChangeMetricPeriodRange(value) {
      if(!value) {
        this.date = null;
        return;
      }

      const from = (value.from instanceof Date && !isNaN(value.from)) ? value.from : new Date(value.from);
      const to = (value.to instanceof Date && !isNaN(value.to)) ? value.to : new Date(value.to);

      // check if same date is already set
      if(
        (this.dateFrom && this.dateFrom.getTime() === from.getTime()) &&
        (this.dateTo && this.dateTo.getTime() === to.getTime())
      ) {
        return;
      }

      this.date = [from, to];
    }

  }
});
</script>

<style scoped>

</style>