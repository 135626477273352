
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {pick} from "lodash";
import {BButton, BCol, BRow} from "bootstrap-vue-3";
import Draggable from 'vuedraggable'
import MetricPresetMixin from "@/views/stats/facebook/mixins/metricPreset";

export default defineComponent({
  name: "Columns",
  mixins: [
    MetricPresetMixin,
  ],

  components: {
    BButton,
    BCol,
    BRow,
    Draggable,
  },

  data(): any {
    return {
      list: {
        enabled: [],
        disabled: [],
      }
    };
  },


  computed: {
    ...mapGetters({
      modal: 'stats/facebook/modalColumns',
      updatedMetricStatsPresetsList: 'automation/metricStats/presets/updatedList',
      metricStatsPresetsListIsUpdating: 'automation/metricStats/presets/listIsUpdating',
    }),

    isVisible: {
      get(): boolean {
        return this.modal.isVisible
      },
      set(value) {
        this.updateModalProperty('isVisible', value)
      },
    },

    updatedMetricStatsPreset(): null|Record<string, any> {
      if(this.presetPathParts.entity === null) return null;
      return this.updatedMetricStatsPresetsList?.[this.presetPathParts.preset]?.[this.presetPathParts.entity] ?? null;
    },

    metricStatsPresetDisabled(): null|Record<string, any> {
      if(
        this.presetPathParts.entity === null ||
        this.metricStatsPreset === null ||
        this.metricStatsGlossaryPreset === null
      ) return null;

      const enabledKeys = Object.keys(this.updatedMetricStatsPreset ?? this.metricStatsPreset);
      const disabledKeys = Object.keys(this.metricStatsGlossaryPreset).filter(x => !enabledKeys.includes(x));

      return pick(this.metricStatsGlossaryPreset, disabledKeys);
    },
  },

  methods: {
    ...mapMutations({
      updateModal: 'stats/facebook/updateModal',
      updateMetricStatsPreset: 'automation/metricStats/presets/updatePreset',
    }),
    ...mapActions({
       saveMetricStatsPreset: 'automation/metricStats/presets/updatePreset',
    }),

    updateModalProperty(key: string, value: any) {
      this.updateModal({
        columns: {
          [key]: value,
        }
      })
    },

    revertList() {
      if(this.presetPathParts.entity === null) return null;

      // "unset" updated preset, so that "current" "user defined" one is reapplied
      this.updateMetricStatsPreset({
        preset: this.presetPathParts.preset,
        entity: this.presetPathParts.entity,
        list: null,
      });

      this.initLists();
    },

    init() {
      this.updateModal({columns: {isLoading: true}});
      Promise.all([
        this.metricsGlossaryFetch(),
        this.metricStatsPresetsGlossaryFetch(),
        this.metricStatsPresetsFetch(),
      ]).then(() => {
        this.initLists();
        this.updateModal({columns: {isLoading: false}});
      });
    },

    initLists() {
      this.initEnabledList();
      this.initDisabledList();
    },

    initEnabledList() {
      const metricStatsPreset = this.updatedMetricStatsPreset ?? this.metricStatsPreset;
      if(metricStatsPreset === null) {
        this.list.enabled = [];
        return;
      }
      // this.metricsGlossary
      this.list.enabled = Object.entries(metricStatsPreset).map((item: any[]) => {
        const key = item[0];
        const data = item[1];

        const metricType = data.metric ?? null;
        const metric = (metricType && metricType in this.metricsGlossary)
          ? this.metricsGlossary[metricType]
          : {};

        const label = metric?.labels?.datagrid ?? metric?.labels?.short ?? metric?.labels?.default ?? key;

        return {
          key,
          label,
          data,
        };
      }).sort(function(elementA, elementB) {
        const elementAIndex = elementA.data?.datagrid?.index ?? 0;
        const elementBIndex = elementB.data?.datagrid?.index ?? 0;
        if(elementAIndex === elementBIndex) return 0;
        return (elementAIndex < elementBIndex) ? -1 : 1;
      });
    },

    initDisabledList() {
      if(this.metricStatsPresetDisabled === null) {
        this.list.disabled = [];
        return;
      }
      this.list.disabled = Object.entries(this.metricStatsPresetDisabled).map((item: any[]) => {
        const key = item[0];
        const data = item[1];

        const metricType = data.metric ?? null;
        const metric = (metricType && metricType in this.metricsGlossary)
          ? this.metricsGlossary[metricType]
          : {};

        const label = metric?.labels?.datagrid ?? metric?.labels?.short ?? metric?.labels?.default ?? key;

        return {
          key,
          label,
          data,
        };
      });
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChangeEnabledList(event) {
      const updatedPreset = this.list.enabled.reduce(function(result, item, index) {
        result[item.key] = {
          ...item.data,
          // preserve only updated index in datagrid attribute
          datagrid: { index }
        };

        return result;
      }, {});

      this.updateMetricStatsPreset({
        preset: this.presetPathParts.preset,
        entity: this.presetPathParts.entity,
        list: updatedPreset,
      });
    },

    resetList() {
      if(!this.metricStatsGlossaryPreset) return;

      const defaultList = Object.keys(this.metricStatsGlossaryPreset)
      .filter(key => (this.metricStatsGlossaryPreset[key]?.datagrid?.enabled ?? true))
      .reduce((result, key) => {
        result[key] = {...this.metricStatsGlossaryPreset[key]};
        return result;
      }, {});

      this.updateMetricStatsPreset({
        preset: this.presetPathParts.preset,
        entity: this.presetPathParts.entity,
        list: defaultList,
      });

      this.initLists();
    },

    savePreset() {
      this.saveMetricStatsPreset(this.presetPathParts);
    }
  },

  mounted() {
    if(!this.modal.isVisible) return;
    if(this.modal.isLoading) return;

    this.init();
  },

  watch: {
    'modal.isVisible'(isVisible: boolean) {
      if(!isVisible) return;
      if(this.modal.isLoading) return;

      this.init();
    },

  },
});
