<template>
    <b-dropdown id="facebook-stats-filter-selector"
                variant="primary"
                menu-class="dropdown-menu-flex"
                toggle-class="text-decoration-none"
                no-caret>
      <template v-slot:button-content>
        <i class="fas fa-plus" /> Add Filter
      </template>

      <template v-for="(optionGroup, optionGroupIndex) in filterOptions"
                :key="`filter-option-group-${optionGroupIndex}`">
        <b-dropdown-group :header="optionGroup.label">
          <b-dropdown-item-button v-for="option in optionGroup.list"
                                  :key="`filter-option-item-${option.gui.filterKey}`"
                                  v-on:click="selectFilter(option)">
            {{ option.gui.filterLabel }}
          </b-dropdown-item-button>
        </b-dropdown-group>
        <b-dropdown-divider v-if="filterOptions.length-1 !== optionGroupIndex" />
      </template>

      <b-dropdown-text v-if="filterOptions.length === 0">
        <span class="text-nowrap">All filters are already selected !</span>
      </b-dropdown-text>
    </b-dropdown>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {
  FilterItemSettings,
  FilterGroup,
  FilterGroups,
  FilterItemPayload
} from "@/store/modules/stats/facebook/state/scopeDynamicFilters";
import {mapGetters, mapMutations} from "vuex";
import {BDropdownText} from "bootstrap-vue-3";


export default defineComponent({
  name: "FilterSelector",
  components: {BDropdownText},
  computed: {
    ...mapGetters({
      scopeFilters: 'stats/facebook/scopeFilters',
      scopeDynamicFilters: 'stats/facebook/scopeDynamicFilters'
    }),
    filterOptions(): FilterGroups {

      const scopeFilterKeys = this.scopeFilters?.map((item: FilterItemPayload) => item.key) ?? [];
      return this.scopeDynamicFilters
        .map((filterGroup: FilterGroup) => {
          const filterGroupClone = {...filterGroup};
          filterGroupClone.list = filterGroup.list.filter((item: FilterItemSettings) => {
            return !scopeFilterKeys.includes(item.gui?.filterKey);
          });

          return filterGroupClone;
        })
        .filter(filterGroup => filterGroup.list.length !== 0);
    }
  },

  methods: {
    ...mapMutations({
      setScopeFilter: 'stats/facebook/setScopeFilter'
    }),
    selectFilter(filter) {
      this.setScopeFilter({
        key: filter.gui?.filterKey,
        settings: filter,
        value: null
      });
    }
  }
});
</script>

<style scoped>

</style>